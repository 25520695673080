export const JOBS_DATA = [
  {
    info: {
      title: 'Game Designer',
      position: 'full time',
      location: 'remote',
    },
    description: '',
  },
  {
    info: {
      title: 'Senior Technical Artist',
      position: 'full time',
      location: 'remote',
    },
    description: '',
  },
  {
    info: {
      title: 'Web Product Manager',
      position: 'full time',
      location: 'remote',
    },
    description: '',
  },
]

export const JOBS_DATA_MAP = Object.entries(JOBS_DATA).reduce(
  (acc, item, i) => ({ ...acc, [i + 1]: item }),
  {},
)
