/* eslint-disable max-len */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable import/prefer-default-export */

export function IconArrowLeft() {
  return (
    <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Arrow - Left - Rounded">
        <path
          id="Vector"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.8354 21.9916C14.5604 21.2666 14.8021 20.1791 14.0771 19.3333L12.1437 16.7958L22.6562 16.7958C23.7437 16.7958 24.4687 15.95 24.4687 14.9833L24.4687 13.775C24.4687 12.6875 23.6229 11.9625 22.6562 11.9625L12.1437 11.9625L14.0771 9.42497C14.6812 8.57913 14.5604 7.3708 13.7146 6.76663L12.7479 6.04163C11.9021 5.43747 10.8146 5.5583 10.0896 6.40413L5.01458 12.9291C4.28958 13.775 4.28958 14.9833 5.01458 15.95L10.2104 22.3541C10.8146 23.2 12.0229 23.3208 12.8687 22.7166L13.8354 21.9916Z"
          fill="white"
        />
      </g>
    </svg>
  )
}
