import React from 'react'
import { useParams } from 'react-router-dom'
import styles from './styles.module.scss'
import GameDesigner from './GameDesigner'
import SeniorTechnicalArtist from './SeniorTechnicalArtist'
import WebProductManager from './WebProductManager'
import GradientAnimation from '../components/GradientAnimation'

function JobsDetails() {
  const { id } = useParams()

  return (
    <div className={styles.container}>
      <GradientAnimation />
      {id === '1' && <GameDesigner />}
      {id === '2' && <SeniorTechnicalArtist />}
      {id === '3' && <WebProductManager />}
    </div>
  )
}

export default JobsDetails
