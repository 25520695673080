import React, { useRef, useEffect, useState } from 'react'
import Signature from 'assets/ourVision/signature.png'
import peerLogo from 'assets/PeerLogo.svg'
import styles from './animated.module.scss'

function AnimatedSection() {
  const textRef = useRef(null)
  const textCloneRef = useRef(null)
  const [scrollHeight, setScrollHeight] = useState(0)

  useEffect(() => {
    const handleScroll = () => {
      const textElement = textRef.current
      const textTop = textElement.getBoundingClientRect().top
      const textHeight = textElement.offsetHeight
      const windowHeight = window.innerHeight
      const middleOfViewport = windowHeight / 2

      if (textTop <= middleOfViewport && textTop + textHeight >= middleOfViewport) {
        const visiblePart = Math.min(middleOfViewport - textTop, textHeight)
        setScrollHeight(visiblePart)
      } else if (textTop > middleOfViewport) {
        setScrollHeight(0)
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    if (textCloneRef.current && textCloneRef.current.innerHTML === '') {
      const textElement = textRef.current.innerHTML
      textCloneRef.current.innerHTML = textElement
    }
  }, [])

  return (
    <div style={{ color: 'white' }} className={styles.wrapper}>
      <div className={styles.text_content} ref={textRef}>
        <div
          className={styles.text_clone}
          ref={textCloneRef}
          style={{ height: `${scrollHeight}px` }}
        />

        <h2 className={styles.title}>There’s no magic without story</h2>

        <div className={styles.text}>
          <p>
            Hi, I'm Tony, founder and CEO of Peer. Peer is an AI company that’s aiming to change the
            way people experience AI.
          </p>
          <p>
            A sea change is approaching, echoing the excitement and transformative energy of the
            early internet boom of the 2000s and the subsequent mobile revolution. AI stands poised
            to surpass even those remarkable innovations, set to revolutionize how we live, work,
            and connect in ways we’re only beginning to imagine.
          </p>
          <p>
            When people ask me what we do at Peer, I often say that “we’re bottling magic.” What is
            magic without a wand? Or a lamp? Or the hero that wields it? Or simply a bottle of
            potion? That’s where the magic truly lies—it’s all about the story. AI, in many ways, is
            like magic—formless, all-powerful, all-encompassing, and full of potential. But for
            people to truly understand it, to make it a part of their lives, we need to transform it
            into something tangible, something they can see, use, and share. Just as all of
            humanity’s potential—and the magic we create—depends on the world we live in, AI needs
            its own world to realize its potential. At Peer, we’re dreaming of building that world.
          </p>
          <p>
            Peer is where we can enter the AI world, and where the AI world can seamlessly blend
            into ours. We’re not just building a new technology; this about crafting the experiences
            that make AI a living part of our reality. Our platform is where human creativity meets
            AI intelligence, creating a space where both can thrive and evolve together.
          </p>
          <p>
            I’m thrilled to welcome you on this journey as we build and shape the next era in
            human-computer interaction. To keep up with the latest from Peer, find us on{' '}
            <a
              className={styles.link_animation}
              href="https://www.linkedin.com/in/mralfa"
              target="_blank"
              rel="noreferrer"
            >
              LinkedIn
            </a>{' '}
            and{' '}
            <a
              className={styles.link_animation}
              href="https://twitter.com/peernewworld"
              target="_blank"
              rel="noreferrer"
            >
              X
            </a>
            .
          </p>
        </div>
      </div>

      <img className={styles.signature} src={Signature} alt="Tony signature" />

      <div className={styles.section_footer}>
        <img src={peerLogo} alt="peer logo" className={styles.peerLogo} />
        <span className={styles.founder_info}>TONY TRAN, FOUNDER & CEO</span>
      </div>
    </div>
  )
}

export default AnimatedSection
