import React from 'react'

import BackButton from '../components/BackButton/index'
import TitleWithApply from '../components/TitleWithApply/index'
import Subtitle from '../components/Subtitle/index'
import Section from '../components/Section/index'
import Paragraph from '../components/Paragraph/index'
import Heading from '../components/Heading/index'
import LI from '../components/LI/index'
import UL from '../components/UL/index'

export default function SeniorTechnicalArtist() {
  return (
    <>
      <BackButton />
      <TitleWithApply>Senior technical artist/pipeline and tools development</TitleWithApply>
      <Subtitle>Other - remote</Subtitle>

      <Section>
        <Heading>About Peer</Heading>
        <Paragraph>
          Peer builds a gamified social ecosystem on a proprietary, immersive 3D map. Currently, it
          consists of a mobile app and a web marketplace, and interactions are based on location
          sharing. This is just the beginning—the product vision has a broader horizon.
        </Paragraph>
        <Paragraph>
          We are building a meta-world that becomes a literal peer to your real world.
        </Paragraph>
        <Paragraph>
          The team you will be joining is not just playing around with social media - we are a team
          of tech, product, art, and design seniors creating technologies to enable innovative
          social experiences that have not existed before. And we are just getting started.
        </Paragraph>
        <Paragraph>
          Creative art and strong visuals, both 2D and 3D, are at the core of our products, gamified
          interactions, and UX.
        </Paragraph>
      </Section>

      <Section>
        <Heading>About You</Heading>
        <Paragraph>
          <b>We are looking for a seasoned 3D technical artist</b> with experience in gaming and
          mobile application pipeline and production. As we create experiences that do not exist
          yet, we need a candidate with a creative tech vision and gaming experience. Senior
          Technical Artist / Pipeline and Tools Development2This role requires working with
          cross-functional teams (the web, mobile, and marketing teams) to deliver products that
          meet user needs and our business objectives and create space for novel interactions.
        </Paragraph>
        <Paragraph>
          The candidate should be a creative technologist with a unique art aesthetic and a good mix
          of artistic and “softˮ skills. The candidate should also have experience collaborating
          with engineers, product designers, animators, and 2D artists on both the web and mobile
          apps.
        </Paragraph>
        <Paragraph>
          As we trace the yet-unknown trails, we seek a go-getter approach, a great team spirit, and
          someone open to continuous learning.
        </Paragraph>
      </Section>

      <Section>
        <Heading>From an artistic POV, you have:</Heading>
        <UL>
          <LI>
            A portfolio of 3D Art (including successfully launched projects) where the pipeline and
            integrations were done by you
          </LI>
          <LI>Experience with procedural asset generation</LI>
          <LI>Experience with accessibility, localization, and internationalization</LI>
          <LI>Features planning, prototyping, visual development</LI>
          <LI>Strong creative vision with attention to business objectives</LI>
          <LI>A plus would be if you can show a good grasp of UX/UI</LI>
        </UL>
      </Section>

      <Section>
        <Heading>From a technical art POV, you have experience in:</Heading>
        <UL>
          <LI>Design systems and tokenization of assets</LI>
          <LI>Creating highly optimized asset pipelines and tooling development for 3D engines</LI>
          <LI>Working with proprietary engines and understanding of shading algorithms</LI>
          <LI>Shader development, prototyping, 3D, 2D, textures…</LI>
          <LI>Modeling as a 3D artist</LI>
          <LI>Working with 3D and 2D animations</LI>
          <LI>Creating 2D pipelines</LI>
          <LI>Prototyping and Feature Design</LI>
          <LI>3D/2D maps prototyping using OSM Data (this is a very strong plus)</LI>
          <LI>Syncing between artists and engineers</LI>
        </UL>
      </Section>

      <Section>
        <Heading>Required skills and qualifications</Heading>
        <UL>
          <LI>
            Eight or more years of professional experience in 3D technologies, graphic and motion
            design, UI, and UX
          </LI>
          <LI>
            Proficiency with software such as Adobe Creative Suite, Figma, Houdini, Blender, Unity
            and/or Unreal Engine, Github, SVN, Custom Graphic Engines
          </LI>
          <LI>Experience in Python, VEX, and JS</LI>
          <LI>Experience with Version control, Github, and SVN</LI>
          <LI>Experience with Substance Painter and Designer</LI>
          <LI>Experience with Render Farms or large amounts of data computing</LI>
          <LI>Experience with 3D production (in gaming / mobile applications)</LI>
        </UL>
      </Section>

      <Section>
        <Heading>Objectives of this role</Heading>
        <UL>
          <LI>
            Create, improve, and automate art and design pipelines - from 2D to rigging to
            prototyping and integration in Engine
          </LI>
          <LI>
            Define and upgrade art department standards for production, productivity, quality, and
            client service
          </LI>
          <LI>
            Ensure the team adheres to current processes, identifies opportunities for improvement,
            and proposes and creates processes and tools to support design operations
          </LI>
          <LI>Creating quality documentation</LI>
          <LI>Solid communication and presentation skills</LI>
          <LI>Participate in the hiring process</LI>
        </UL>
      </Section>
    </>
  )
}
