/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import cls from 'classnames'
import routing from 'routing/path'
import useCurrentSize from 'hooks/useCurrentSize'
import ArrowLeftRounded from 'assets/Home/arrowLeftRounded.svg'
// import Solar from 'assets/Home/video/solar.webm'
import SolarHigh from 'assets/Home/video/solar1080.webm'
import SolarQuad from 'assets/Home/video/solar2048.webm'
import SolarPreloadImg from 'assets/Home/SolarPreload.png'
import SolarPreloadSmallImg from 'assets/Home/SolarPreload_120.png'
import BREAKPOINTS from 'constants/breakpoints'

import Title from './components/Title'
import styles from './home.module.scss'

function Home() {
  const [sources, setSources] = useState({ video: SolarHigh, image: SolarPreloadImg })
  const [isVideoLoaded, setIsVideoLoaded] = useState(false)
  const [isImageLoaded, setIsImageLoaded] = useState(false)
  const [isSmallImageLoaded, setIsSmallImageLoaded] = useState(false)

  const { width } = useCurrentSize()
  const isMobile = width < BREAKPOINTS.md

  useEffect(() => {
    const updateVideoSource = () => {
      const screenWidth = window.innerWidth

      if (screenWidth > BREAKPOINTS.xl) {
        setSources({ video: SolarQuad, image: SolarPreloadImg })
      } else if (screenWidth > BREAKPOINTS.md) {
        setSources({ video: SolarHigh, image: SolarPreloadImg })
      } else {
        // todo: check if higher resolution can be used for mobile. remove after tests
        // setSources({ video: Solar, image: SolarPreloadImg })
        setSources({ video: SolarHigh, image: SolarPreloadImg })
      }
    }

    updateVideoSource()

    window.addEventListener('resize', updateVideoSource)

    return () => {
      window.removeEventListener('resize', updateVideoSource)
    }
  }, [])

  const handleVideoLoaded = () => {
    setIsVideoLoaded(true)
  }

  const handleImageLoaded = () => {
    setIsImageLoaded(true)
  }

  const handleSmallImageLoaded = () => {
    setIsSmallImageLoaded(true)
  }

  return (
    <div className={styles.wrapper}>
      {(isSmallImageLoaded || isVideoLoaded || isImageLoaded) && <Title />}

      <div className={styles.video}>
        <video
          src={sources.video}
          autoPlay
          playsInline
          loop
          muted
          onCanPlayThrough={handleVideoLoaded}
          style={{ display: isVideoLoaded ? 'block' : 'none' }}
        />

        {!isVideoLoaded && (
          <img
            src={sources.image}
            alt="Solar"
            onLoad={handleImageLoaded}
            className={cls({ [styles.opacity]: isImageLoaded })}
          />
        )}
        {!isVideoLoaded && !isImageLoaded && (
          <img
            src={SolarPreloadSmallImg}
            alt="Solar"
            className={cls({ [styles.opacity_delay]: isSmallImageLoaded })}
            onLoad={handleSmallImageLoaded}
          />
        )}
      </div>

      {isMobile && (
        <div className={styles.links_container}>
          <Link to={routing.withoutAuth.ourVision} className={styles.largeLink}>
            Our Vision
            <img src={ArrowLeftRounded} alt="Arrow" />
          </Link>
        </div>
      )}
    </div>
  )
}

export default React.memo(Home)
