export default {
  phone: 480,
  sm: 576,
  md: 768,
  lg: 962,
  xl: 1200,
  mHd: 1400,
  large: 1500,
  fullHd: 1900,
}
