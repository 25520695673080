import React from 'react'

import BackButton from '../components/BackButton/index'
import TitleWithApply from '../components/TitleWithApply/index'
import Subtitle from '../components/Subtitle/index'
import Section from '../components/Section/index'
import Paragraph from '../components/Paragraph/index'
import Heading from '../components/Heading/index'
import LI from '../components/LI/index'
import UL from '../components/UL/index'
import OL from '../components/OL/index'

export default function WebProductManager() {
  return (
    <>
      <BackButton />
      <TitleWithApply>Web Product Manager</TitleWithApply>
      <Subtitle>Other - remote</Subtitle>

      <Section>
        <Heading>About Peer</Heading>
        <Paragraph>
          Peer creates a gamified social ecosystem using a unique, immersive 3D map. Our platform
          includes a mobile app and a web marketplace, with interactions driven by location sharing.
          This is just the beginning—the product vision has a broader horizon.
        </Paragraph>
        <Paragraph>
          We're building innovative technologies enabling novel experiences that bring people
          together in new and exciting ways. Our team is passionate about pushing the boundaries of
          what's possible in gaming and social interaction, and we're looking for a talented Web
          Product Manager to help us shape that future.{' '}
        </Paragraph>
      </Section>

      <Section>
        <Heading>Key Responsibilities:</Heading>
        <OL>
          <LI>
            <Heading>Key Responsibilities:</Heading>
            <UL>
              <LI>
                Develop and articulate a clear product vision and strategy for our web platforms
                that align with our companyʼs goals and user needs.
              </LI>
              <LI>
                Conduct market research, analyze industry trends, and gather user feedback to inform
                product decisions and identify new opportunities.
              </LI>
            </UL>
          </LI>
          <LI>
            <Heading>Roadmap and Planning:</Heading>
            <UL>
              <LI>
                Create and maintain a product roadmap that outlines key features, enhancements, and
                milestones.
              </LI>
              <LI>
                Prioritize product features and initiatives based on business value, user impact,
                and technical feasibility.
              </LI>
            </UL>
          </LI>
          <LI>
            <Heading>Cross-Functional Collaboration:</Heading>
            <UL>
              <LI>
                Work closely with engineering, product, design, and other teams to define product
                requirements, user stories, and acceptance criteria.
              </LI>
              <LI>
                Facilitate communication and collaboration across teams to ensure alignment and
                successful delivery of web products.
              </LI>
            </UL>
          </LI>
          <LI>
            <Heading>User Experience and Design:</Heading>
            <UL>
              <LI>
                Advocate for a user-centered approach in all aspects of product development,
                ensuring that web experiences are intuitive, engaging, and visually compelling.
              </LI>
              <LI>
                Collaborate with designers to create wireframes, mockups, and prototypes and conduct
                usability testing to validate design decisions.
              </LI>
            </UL>
          </LI>
          <LI>
            <Heading>Product Development and Execution:</Heading>
            <UL>
              <LI>
                Oversee the entire product development lifecycle from concept to launch, ensuring
                timely delivery and high-quality standards.
              </LI>
              <LI>
                Monitor project progress, manage risks, and resolve issues impacting product
                delivery.
              </LI>
            </UL>
          </LI>
          <LI>
            <Heading>Performance and Optimization:</Heading>
            <UL>
              <LI>
                Analyze web product performance using metrics and user feedback to identify areas
                for improvement and optimization.
              </LI>
              <LI>
                Implement A/B testing, user segmentation, and other techniques to continuously
                enhance product performance and user satisfaction.
              </LI>
            </UL>
          </LI>
          <LI>
            <Heading>Stakeholder Management:</Heading>
            <UL>
              <LI>
                Communicate product updates, progress, and insights to stakeholders, including
                executives, partners, and internal teams.
              </LI>
              <LI>
                Gather and address feedback from stakeholders to ensure alignment with business
                objectives and user needs.
              </LI>
            </UL>
          </LI>
        </OL>
      </Section>

      <Section>
        <Heading>Qualifications:</Heading>
        <UL>
          <LI>
            Bachelorʼs degree in Computer Science, Business, Marketing, or a related field; advanced
            degree is a plus.
          </LI>
          <LI>
            5+ years of experience in product management, focusing on web-based products and
            platforms.
          </LI>
          <LI>
            Proven track record of successfully launching and managing web products in the gaming or
            social technology space.
          </LI>
          <LI>
            Web Product Manager3Strong understanding of web technologies, UX/UI design principles,
            and product development methodologies.
          </LI>
          <LI>
            Excellent analytical, problem-solving, and communication skills, with the ability to
            articulate complex ideas clearly and persuasively.
          </LI>

          <LI>Experience with agile development methodologies and project management tools.</LI>
          <LI>
            Passion for gaming and social technology, with a deep understanding of current trends
            and user behaviors.
          </LI>
        </UL>
      </Section>

      <Section>
        <Heading>What We Offer:</Heading>
        <UL>
          <LI>Competitive salary</LI>
          <LI>Cutting edge technology</LI>
          <LI>Flexible work hours and fully remote work</LI>
          <LI>Opportunities for professional growth and development.</LI>
          <LI>A dynamic and collaborative work environment where innovation is encouraged.</LI>
        </UL>
      </Section>

      <Section>
        <Paragraph>
          We'd love to hear from you if you're a visionary Web Product Manager passionate about
          innovative gaming and social technology. Apply today to join our team and help us create
          the next generation of digital experiences!
        </Paragraph>
      </Section>

      <Section>
        <Heading>How to Apply:</Heading>
        <Paragraph>
          Please submit your resume, cover letter, and a portfolio of relevant work through our
          website. Peer is an equal-opportunity employer. We celebrate diversity and are committed
          to creating an inclusive environment for all employees.
        </Paragraph>
      </Section>
    </>
  )
}
