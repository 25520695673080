import React from 'react'
import Typography from 'components/Typography/index'
import styles from './about.module.scss'

const { Heading } = Typography

function AboutUs() {
  return (
    <div className={styles.section}>
      <Heading size={1} className={styles.title}>
        About us
      </Heading>
      <p className={styles.text}>
        Peer is an AI experience company. We’re committed to making AI innovations practical for
        everyday life and expediting its transformative impact.{' '}
      </p>
      <p className={styles.text}>
        To that end, we’ve overcome incredible challenges to build a human-centered AI platform that
        can generate immersive 3D worlds you can walk through, interact with and build upon. This
        environment doesn’t just create experiences; it enables AI to see, comprehend, and engage
        with the world as we do, accelerating the development of AI models with true spatial
        intelligence.
      </p>
      <p className={styles.text}>
        As we advance, our platform sets the stage for AI to seamlessly live and operate in both our
        world and its own, bridging the gap between reality and imagination.
      </p>
      <p className={styles.text}>
        We believe this platform is the cradle for artificial superintelligence, and we invite you
        to join us on this transformative journey.
      </p>
    </div>
  )
}

export default AboutUs
