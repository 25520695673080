import React from 'react'
import styles from './investors.module.scss'

function FoundingInvestors() {
  return (
    <div className={styles.section}>
      <h2 className={styles.title}>Our founding investors</h2>
      <p className={styles.subtitle}>Peer is graciously supported by:</p>
      <p className={styles.investors_names}>
        David H Nguyen, David Tran, Hannah Vu, Hue Nguyen, Jan Nguyen, John Nguyen, Khanh-Trang Le,
        Khanh-Van Nguyen, Ken Huynh, Lan Pham, Long Le, Marie To, Paul T. Duong, Quan Nguyen, Thuy
        Trina Nguyen, Toan Nguyen, Tommy Mai, Trang Thanh, Tu Nguyen.
      </p>
      <p>
        Our founding investors are visionary change-makers who share and shape Peer's pioneering
        spirit. Their contributions go beyond financial support—they are helping to drive the
        creation of a new digital frontier that will enable AI to reach its full potential.
      </p>
    </div>
  )
}

export default FoundingInvestors
